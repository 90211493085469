import { Guid } from "./guid";

/**
 * Provides commonly used methods.
 *
 * @export
 */
export class __ {
  /**
   * Checks whether the passed argument is null or undefined, or not.
   *
   * @param argument The argument which should be checked for null or undefined.
   * @returns True, if the passed argument is null or undefined, false otherwise.
   * @example
   *
   * // Returns true
   * const result: boolean = __.IsNullOrUndefined(null);
   *
   * // Returns true
   * const result: boolean = __.IsNullOrUndefined(undefined);
   *
   * // Returns false
   * const result: boolean = __.IsNullOrUndefined(6);
   */
  static IsNullOrUndefined(argument: any) {
    return typeof argument === 'undefined' || argument === null;
  }
  static IsNullOrUndefinedOrFalse(argument: any) {
    return typeof argument === 'undefined' || argument === null || argument === false;
  }
  /**
   * Checks whether the passed argument is null, undefined or empty, or not.
   *
   * @param argument The argument which should be checked for null, undefined or empty.
   * @returns True, if the passed argument is null, undefined or empty, false otherwise.
   * @example
   *
   * // Returns true
   * const result: boolean = __.IsNullOrUndefined(null);
   *
   * // Returns true
   * const result: boolean = __.IsNullOrUndefined(undefined);
   *
   * // Returns true
   * const result: boolean = __.IsNullOrUndefined('');
   *
   * // Returns false
   * const result: boolean = __.IsNullOrUndefined('Notempty');
   */
  static IsNullOrUndefinedOrEmpty(argument: any) {
    return typeof argument === 'undefined' || argument === null || argument === '' || argument.length === 0 || argument === Guid.Empty;
  }

  /**
   * Removes a provided item from a provided array based on the selector function. Optionally a function can be
   * provided that will be executed, if the provided item is not found in the provided array.
   *
   * @param array The array from which the item should be removed
   * @param item The item that should be removed from the array
   * @param selector The selector function that is being used to do the equality comparison
   * @param elseFunction An optional function that will be executed, if the item does not exist in the array
   * @returns The removed element, if the element is found an successfully removed. If an else function is provided, the return
   * value of that function will be returned. If none is provided, the return type is void
   */
  static RemoveFromArray<T>(array: T[], item: T, selector: (item: T) => any, elseFunction?: () => any): T | any | void {
    const _selector = __.IsNullOrUndefined(selector) ? (a: T) => a : selector;

    const index = array.findIndex(q => _selector(item) === _selector(q));
    if (index > -1) {
      return array.splice(index, 1);
    } else {
      if (!__.IsNullOrUndefined(elseFunction)) {
        return elseFunction();
      }
    }
  }

  /**
   * A Javascript adaption of C#s String.Format method with integer placeholders. Especially used for i18n integration.
   *
   * @param string The string in wich content should be injected
   * @param data One or several objects which should be injected to the provided string
   * @returns The string with injected values
   * @example
   *
   * // Use to replace one placeholder
   * let injectedString = __.Inject('Item amount: {0}', 20);
   *
   * // Or use to replace multiple placeholders
   * let injectedString = __.Inject('Total price: {0} - Total amount: {1}', [200, 5]);
   */
  static Inject(string: string, data: Object | Array<any>): string | boolean {
    if (typeof string === 'string' && data instanceof Array) {
      return string.replace(/({\d})/g, function (i) {
        return data[i.replace(/{/, '').replace(/}/, '')];
      });
    } else if (typeof string === 'string' && data instanceof Object) {
      for (let key in data) {
        if (data.hasOwnProperty(key)) {
          return string.replace(/({([^}]+)})/g, function (i) {
            key = i.replace(/{/, '').replace(/}/, '');
            if (!data[key]) {
              return i;
            }
            return data[key];
          });
        }
      }
    } else {
      return false;
    }
  }

  /**
   * Checks if a provided element is the last element of a provided array
   *
   * @param element The element which should be checked for the last position in the array
   * @param array The array which is being used to check if the element is the last element
   * @returns True if the provided element is the last in the provided array
   * @memberof __
   */
  static IsLastInArray(element: any, array: any[]): boolean {
    return array[array.length - 1] === element;
  }

  /**
   * Deep copies a passed object and returns it
   *
   * @export
   * @param from The object that should be deep copied
   * @returns The deep copy of the provided object
   */
  static Extend(from: any): any {
    if (from === null || typeof from !== 'object') return from;
    if (from.constructor !== Object && from.constructor !== Array) return from;
    if (
      from.constructor === Date ||
      from.constructor === RegExp ||
      from.constructor === Function ||
      from.constructor === String ||
      from.constructor === Number ||
      from.constructor === Boolean
    )
      return new from.constructor(from);
    const to = new from.constructor();
    for (const name in from) {
      if (from.hasOwnProperty(name)) {
        to[name] = typeof to[name] === 'undefined' ? __.Extend(from[name]) : to[name];
      }
    }
    return to;
  }

  public static Random(min: number, max: number) {
    return Math.round((Math.random() * (Math.abs(max - min))) + min);
  }

  public static RandomString(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }

  /**
   * Flattens a list in a list to a list.
   * 
   * Use this function as the callbackFn for the array.reduce function.
   * @param a An element of the list
   * @param v An element of the list
   */
  static Flatten<T>(a: T[], v: T[]): T[] {
    return a.concat(v);
  }

  static Distinct<T>(array: T[], selector: (item: T) => any): T[] {
    return array.filter((value, index, self) => {
      return self.findIndex(q => selector(q) === selector(value)) === index
    })
  }

  // __.Sort(this.invitedUsers, q => q.lastname, SortOrder.Ascending);
  static Sort<T>(entities: T[], prop: (c: T) => string | number, order: SortOrder): T[] {
    entities.sort((a, b) => {
      if (prop(a) < prop(b)) {
        return -1;
      }
      if (prop(a) > prop(b)) {
        return 1;
      }
      return 0;
    });

    if (order === SortOrder.Descending) {
      entities.reverse();
    }

    return entities;
  }

  static Dasherize(value: string): string {
    return value.replace(/[A-Z]/g, (char, index) => {
      return (index !== 0 ? '-' : '') + char.toLowerCase();
    }).replace(/\s+/g, '-').toLowerCase();
  }

  static Camelize(str: string): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }

  static IsObject(obj: any): boolean {
    return obj != null && obj.constructor.name === "Object"
  }

}

export enum SortOrder {
  Ascending = 'Ascending',
  Descending = 'Descending'
}