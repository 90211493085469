import { Directive, HostBinding, Input, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { __ } from '../../functions/object.functions';
import { BaseModel } from '../../models/classes/base/BaseModel';

/**
 * This class handles the subscribing and unsubscribing of subscriptions to avoid memory leaks
 * and can be inherited by members
 *
 * @export
 */
@Directive()
export abstract class BaseComponent implements OnDestroy {

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  IsNullOrUndefined = __.IsNullOrUndefined;

  IsNullOrUndefinedOrEmpty = __.IsNullOrUndefinedOrEmpty;

  // -----------------------------------------------------------------------------------------------------
  // @ INPUT VARIABLES
  // -----------------------------------------------------------------------------------------------------

  @Input() isLoading = false;

  // -----------------------------------------------------------------------------------------------------
  // @ HOST BINDINGS
  // -----------------------------------------------------------------------------------------------------

  @HostBinding('class') class: string;

  @HostBinding('style') style: string;

  @HostBinding('style.display') display: string = 'block';

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private subscriptions: Subscription[] = new Array<Subscription>();

  // -----------------------------------------------------------------------------------------------------
  // @ LIFE CYCLE HOOKS
  // -----------------------------------------------------------------------------------------------------

  ngOnDestroy() {
    this.removeSubscriptions();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  compareById = (c1: BaseModel, c2: BaseModel) => {
    if (!__.IsNullOrUndefined(c1) && !__.IsNullOrUndefined(c2)) {
      return c1.id === c2.id;
    }
    return c1 === c2;
  };

  log($event: any): void {
    console.log($event);
  }

  trackById: (entity: any) => any = (entity: any) => entity.id;

  @Input() trackBy: (entity: any) => any = (entity: any) => entity;

  finalizeLoading<T>(): MonoTypeOperatorFunction<T> {
    return finalize(() => {
      this.isLoading = false;
    })
  }

  any(item: any): any {
    return item;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PROTECTED METHODS
  // -----------------------------------------------------------------------------------------------------

  /**
   * Adds a subscriptions so it can be deleted in ngOnDestroy
   *
   * @param subscription The subscription that should be added
   * BaseComponent
   */
  protected addSubscription(subscription: Subscription) {
    this.subscriptions.push(subscription);
  }

  protected cancelAllSubscriptions() {
    if (this.subscriptions.length === 0) {
      return;
    }

    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
    this.subscriptions.slice(0);
  }

  /**
   * Unsubscribes from any open subscriptions in the subscriptions array in ngOnDestroy
   *
   * BaseComponent
   */
  protected removeSubscriptions() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }
}
