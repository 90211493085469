import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import moment from 'moment';
import { BaseService } from 'projects/shared/src/lib/shared/base/services';
import { firstValueFrom, Observable, of, Subject, zip } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import { authCodeFlowConfig } from './oauth-config';
import { environment } from "projects/cms/src/environments/environment";
import { credentialsKey } from 'projects/shared/src/lib/shared/constants';
import { HttpCacheService } from 'projects/shared/src/lib/shared/core/http/http-cache.service';
import { __ } from 'projects/shared/src/lib/shared/functions/object.functions';
import { ApplicationUser } from 'projects/shared/src/lib/shared/models/classes/users/ApplicationUser';
import { IdentityToken } from 'projects/shared/src/lib/shared/models/classes/users/IdentityToken';
import { Role } from 'projects/shared/src/lib/shared/models/classes/users/Role';
import { Ticket } from 'projects/shared/src/lib/shared/models/classes/users/Ticket';
import { UsersService } from 'projects/shared/src/lib/shared/services/users.service';

/**
 * Provides a base for authentication workflow.
 * The Credentials interface as well as login/logout methods should be replaced with proper implementation.
 */
@Injectable()
export class AuthenticationService extends BaseService {

  // -----------------------------------------------------------------------------------------------------
  // @ READ ONLY VARIABLES
  // -----------------------------------------------------------------------------------------------------

  readonly clientId = 'siudfho2349!ihasd8gi3$ihsdo';

  readonly clientSecret = 'as9dzho2=gu8aishd8!89h3o';

  // -----------------------------------------------------------------------------------------------------
  // @ PRIVATE INSTANCE VARIABLES
  // -----------------------------------------------------------------------------------------------------

  private _credentials: IdentityToken | null;

  private _credentials$: Subject<IdentityToken> = new Subject<IdentityToken>();

  // tslint:disable-next-line:member-ordering
  credentials$: Observable<IdentityToken> = this._credentials$.asObservable();

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    private httpClient: HttpClient,
    private httpCacheService: HttpCacheService,
    public oAuthService: OAuthService,
    private usersService: UsersService
  ) {
    super();

    const savedCredentials = localStorage.getItem(credentialsKey); // sessionStorage.getItem(credentialsKey) ||

    if (!__.IsNullOrUndefined(savedCredentials)) {
      this._credentials = JSON.parse(savedCredentials);
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -----------------------------------------------------------------------------------------------------

  get user(): ApplicationUser {
    return this._credentials?.user;
  }

  get ticket(): any {
    return {
      accessToken: this._credentials.access_token,
      expires: this._credentials.expires,
      refreshToken: this._credentials.refresh_token
    };
  }

  hasRole(group: string) {
    if (__.IsNullOrUndefinedOrEmpty(group)) {
      return true;
    }
    if (environment.testPermissions === false && environment.production !== true) {
      return true;
    }
    if (!__.IsNullOrUndefined(this.credentials.user.roles)) {
      if (this.isCompanyAdministrator()) {
        return true;
      }
      return this.credentials.user.roles.some(q => q === group);
    }
    return false;
  }

  hasNotRole(group: string): boolean {
    if (__.IsNullOrUndefinedOrEmpty(group)) {
      return false;
    }
    if (environment.testPermissions === false && environment.production !== true) {
      return false;
    }
    if (!__.IsNullOrUndefined(this.credentials.user.roles)) {
      return !this.credentials.user.roles.some(q => q === group);
    }
    return true;
  }

  hasAnyRole(groups: string[]) {
    if (groups.length === 0) {
      return true;
    }
    if (environment.testPermissions === false && environment.production !== true) {
      return true;
    }
    if (!__.IsNullOrUndefined(this.credentials.user.roles)) {
      if (this.isCompanyAdministrator()) {
        return true;
      }

      return groups.findIndex(a => this.credentials.user.roles.some(q => q === a)) > -1;
    }
    return false;
  }

  isCompanyAdministrator(): boolean {
    return this.credentials.user.roles.indexOf(Role.CompanyAdministrator) > -1;
  }

  /**
   * If user email starts with julien, the user is super admin
   * @returns true if user email starts with julien otherwise return false
   */
  isSuperAdmin(): boolean {
    return this.user.email.startsWith('julien');
  }

  isAdministrator(): boolean {
    return this.credentials.user.roles.indexOf(Role.Administrator) > -1;
  }

  isAnyAdministrator(): boolean {
    return this.isAdministrator() || this.isCompanyAdministrator();
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    this.oAuthService.logOut({ client_id: environment.loginClientId});
    this.setCredentials(null);

    return of(true);
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !__.IsNullOrUndefined(this._credentials) && !__.IsNullOrUndefined(this._credentials.user);
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): IdentityToken | null {
    return this._credentials;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: IdentityToken, remember?: boolean) {
    this._credentials = credentials || null;
    this._credentials$.next(this._credentials);

    if (credentials) {
      localStorage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      localStorage.removeItem(credentialsKey);
    }
  }

  checkLoginStatusOrLogin(): Promise<any> {
    this.oAuthService.configure(authCodeFlowConfig);

    if (this.oAuthService.hasValidAccessToken() === false) {
      return this.oAuthService
        .loadDiscoveryDocumentAndLogin(
          { 
            
            customHashFragment: window.location.search
          }
        ).then(() => {
          if (this.oAuthService.hasValidAccessToken()) {
            const token = Object.assign(new IdentityToken(), {
              access_token: this.oAuthService.getAccessToken(),
              refresh_token: this.oAuthService.getRefreshToken(),
              expires: this.oAuthService.getAccessTokenExpiration().toString(),
            } as IdentityToken);

            this.setCredentials(token);

            return firstValueFrom(this.usersService.getCurrentUser()).then((user) => {

              token.user = user;
              this.setCredentials(token);

              return firstValueFrom(of(true));
            })
          } else {
            return firstValueFrom(of(false));
          }
        });
    } else {
      if (__.IsNullOrUndefined(this.credentials.user)) {
        this.logout().subscribe();
      }
      return this.oAuthService.loadDiscoveryDocument();
    }
  }
}

export class AccessTokenRequestBody {
  email: string;
  password: string;
}

export class AccessTokenResponse {
  access_token: string;
  expires: string;
  refresh_token: string;
}
