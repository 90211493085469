import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { I18nService } from 'projects/cms/src/app/core/i18n.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Logger } from '../core/logger.service';
import {
  ApplicationUser,
  ApplicationUserCreateOptions,
  ApplicationUserUpdateOptions,
  ResetPasswordBody,
} from '../models/classes/users/ApplicationUser';
import { EntityService } from './entity.service';
import { Response } from './local/Response';
import { environment } from 'projects/cms/src/environments/environment';

const log = new Logger('Users Service');

@Injectable()
export class UsersService extends EntityService<ApplicationUser, ApplicationUserCreateOptions, ApplicationUserUpdateOptions> {

  // -----------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -----------------------------------------------------------------------------------------------------

  constructor(
    protected httpClient: HttpClient,
    protected i18nService: I18nService
  ) {
    super('users', 'User', httpClient, i18nService);
  }

  update(id: string, entity: ApplicationUserUpdateOptions): Observable<ApplicationUser> {
    return super.update(id, entity);
  }

  getCurrentUser(): Observable<ApplicationUser> {
    return this.httpClient.disableApiPrefix().get<any>(`${environment.idpServerUrl}api/v1/users/me`).pipe(
      map((result: any) => {
        return result.data;
      })
    );
  }

  resetPassword(userId: string, resetPasswordDto: ResetPasswordBody) {

    return this.httpClient.patch<any>(
      `users/${userId}/password`,
      resetPasswordDto
    ).pipe(
      map((result: Response<boolean>) => {
        return result.code.statusCode === 200;
      })
    );
  }

  sendPasswordResetEmail(email: string) {

    return this.httpClient
      .post<any>(
        `users/password-reset`, email
      ).pipe(
        map((result: Response<boolean>) => {
          return result.code.statusCode === 200;
        })
      );
  }

  confirmEmail(userId: string, token: string): Observable<ApplicationUser> {
    return this.httpClient
      .disableAccessToken()
      .post<any>(
        `users/${userId}/confirm-email`,
        { token }
      ).pipe(
        map((result: Response<ApplicationUser>) => {
          return result.data;
        })
      );
  }

  changePassword(currentPassword: string, newPassword: string): Observable<boolean> {
    return this.httpClient
      .patch<Response<boolean>>(`users/me/password`, {
        currentPassword,
        password: newPassword
      })
      .pipe(
        map((result: Response<boolean>) => {
          return result.code.statusCode === 200;
        })
      );
  }

  changePermissions(userId: string, permissions: string[], useCaseIds: string[]): Observable<boolean> {
    return this.httpClient
      .put<Response<boolean>>(`users/${userId}/permissions`, { permissions, useCaseIds })
      .pipe(
        map((result: Response<boolean>) => {
          return result.data;
        })
      );
  }

  changeRoles(userId: string, userRole: string): Observable<boolean> {
    return this.httpClient
      .patch<Response<boolean>>(`users/${userId}/roles`, { role: userRole })
      .pipe(
        map((result: Response<boolean>) => {
          return result.code.statusCode === 200;
        })
      );
  }

  deleteManyByIds(ids: string[]): Observable<boolean> {
    const filter = ids.map(id => `id=${id}&`).join('');
    return this.httpClient.delete<Response<boolean>>(`users?${filter}`).pipe(
      map((result: Response<boolean>) => {
        return result.code.statusCode === 200;
      })
    );
  }
}
